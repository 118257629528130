import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Lottie from "lottie-react";
import _ from 'lodash';
import styles from './index.module.css';
import homeIcon from '../../assets/icons/menu/Home.svg';
import liveTvIcon from '../../assets/icons/menu/LiveTV.svg';
import onDemandIcon from '../../assets/icons/menu/Explore.svg';
import searchIcon from '../../assets/icons/menu/Search.svg';
import moreIcon from '../../assets/icons/menu/More.svg';
import guideIcon from '../../assets/icons/menu/Guide.svg';

import { useNavigate, useLocation } from "react-router-dom";
import { toggleMenu, setModal } from '../../redux-root/actions';
import gtmHelper from '../../utils/gtmHelper';

import searchJson from '../../assets/json/animation/menu/search.json';
import homeJson from '../../assets/json/animation/menu/home.json';
import liveTVJson from '../../assets/json/animation/menu/livetv.json';
import exploreJson from '../../assets/json/animation/menu/ondemand.json';
import guideJson from '../../assets/json/animation/menu/channelguide.json';
import moreJson from '../../assets/json/animation/menu/more.json';

let gtmObj = {
  section: 'left-rail-widget'
};

function NavigationMenu(props) {
  const { prevPath, profileData, authenticated, channelData, hashUserId, modal } = props;

  let morePath = '/login';

  if (authenticated) {
    morePath = '/favourite';
  }

  const [focusList, setFocusList] = useState([
    { id: 'search', name: 'Search', focused: false, searchFocuseImg: searchIcon, path: '/search', gtm: 'search' },
    { id: 'whatHot', name: "Home", focused: false, homeFocuseImg: homeIcon, path: '/', gtm: 'home' },
    { id: 'liveTV', name: 'Live TV', focused: false, liveTvFocuseImg: liveTvIcon, path: '/livetv', gtm: 'live-tv' },
    { id: 'explore', name: 'Explore', focused: false, onDemandFocuseImg: onDemandIcon, path: '/ondemand', gtm: 'on-demand' },
    { id: 'guide', name: 'Guide', focused: false, guideFocuseImg: guideIcon, path: '/guide', gtm: 'guide' },
    { id: 'more', name: 'More', focused: false, moreFocuseImg: moreIcon, path: morePath, gtm: 'more' }
  ]);

  const [focusIndex, _setFocusIndex] = useState(1)
  const focusIndexRef = useRef(focusIndex);
  const setFocusIndex = (data) => {
    focusIndexRef.current = data;
    _setFocusIndex(data);
  };

  const [menuExpand, setMenuExpand] = useState(false)

  const navigate = useNavigate();

  const location = useLocation()

  const [redShadeIndex, setRedShadeIndex] = useState(-1)

  const [lastFocusIndex, setLastFocusIndex] = useState(-1)

  const lottieRefList = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef()
  ];

  useEffect(() => {
    document.removeEventListener("keyup", keyPress);
    if (!props.streamVod) {
      if (!props.collapsed) {
        document.addEventListener("keyup", keyPress);
      } else {
        document.removeEventListener("keyup", keyPress);
      }
    }

    return () => {
      document.removeEventListener("keyup", keyPress);
    };
  }, [menuExpand, props.collapsed, props.streamVod])

  useEffect(() => {
    if (!props.collapsed && lottieRefList[focusIndex].current) {
      setMenuExpand(true)

      lottieRefList[focusIndex].current.goToAndPlay(0);

    } else {
      setMenuExpand(false)
    }
  }, [props.collapsed])

  useEffect(() => {
    let newIndex;

    let pathName = location.pathname
    focusList.map((data, index) => {
      if (data.path === pathName || (prevPath && data.path === prevPath)) {
        newIndex = index;
        setMenuExpand(false)
      }
    });

    if (newIndex === undefined) {
      let isSubscriptions = (window.location.href.indexOf("/subscriptions") !== -1 || (prevPath && prevPath.indexOf("/subscriptions") !== -1));

      let isWatchHistory = (window.location.href.indexOf("/watch-history") !== -1 || (prevPath && prevPath.indexOf("/watch-history") !== -1));

      let isAccount = (window.location.href.indexOf("/account-details") !== -1);

      let isParental = (window.location.href.indexOf("/parental-control") !== -1);

      let isAboutUs = (window.location.href.indexOf("/about-us") !== -1);

      if (isSubscriptions || isWatchHistory || isAccount || isParental || isAboutUs) {
        newIndex = 5;
      } else {
        newIndex = 1;
      }
    }

    setFocusIndex(newIndex);
    setLastFocusIndex(newIndex);
  /* eslint-disable */
  }, []);

  const menuClick = (index, data) => {
    gtmObj.category = 'watchcast-' + focusList[index].gtm;
    gtmObj.userId = hashUserId;

    gtmHelper({
      ...gtmObj,
      subSection: 'NA',
      action: 'button-click',
      label: focusList[index].gtm
    });

    if (index !== undefined) {
      setFocusIndex(index)
      setLastFocusIndex(index)
      if (data.path === '/livetv') {
        const lcid = localStorage.getItem('_lc');

        if (authenticated) {
          if (lcid && profileData.channel.find(c => c.id === lcid)) {
            navigate(`/channel/${lcid}`);
          } else {
            navigate(`/channel/${profileData.channel[0].id}`);
          }
        } else {
          // navigate(`/channel/${channelData.freeChannel[0].id}`);

          props.setModal(
            'Subscribe only',
            'Please login to watch.',
            'Login',
            () => navigate('/login'),
          );
        }
      } else {
        if (data.path === '/login' || data.path === '/guide') {
          setFocusIndex(lastFocusIndex)
          setLastFocusIndex(lastFocusIndex)
          props.setModal(
            'Subscribe only',
            'Please login to watch.',
            'Login',
            () => navigate('/login'),
          );
        } else {
          navigate(data.path)
        }
      }
    }
  }

  const hoverMenu = () => {
    setMenuExpand(true)
  }

  const offMenu = () => {
    setMenuExpand(false)
  }

  const hoverImg = (index) => {
    // setFocusIndex(focusIndex)
    setRedShadeIndex(index);

    if (lottieRefList[index].current) {
      lottieRefList[index].current.goToAndPlay(0);
    }
  }

  const offImg = (index) => {
    setRedShadeIndex(false)
    // setFocusIndex(lastFocusIndex);

    if (lottieRefList[index].current) {
      const totalFrames = lottieRefList[index].current.getDuration(true);
      lottieRefList[index].current.goToAndStop(totalFrames - 1, true);
    }
  }

  const onEnterFrame = (anim, index) => {
    const totalFrames = lottieRefList[index].current.getDuration(true);

    if (anim.currentTime > (totalFrames - 1)) {
      lottieRefList[index].current.pause();
    }
  }

  const onDOMLoaded = (index) => {
    const totalFrames = lottieRefList[index].current.getDuration(true);
    lottieRefList[index].current.goToAndStop(totalFrames - 1, true);
  }

  const path = window.location.pathname;


  const keyPress = (e) => {
    const currentIndex = focusIndexRef.current;

    if (e.keyCode === 40) { // DOWN KEY
      let newFocusIndex = currentIndex + 1

      if (newFocusIndex < focusList.length && menuExpand) {
        setFocusIndex(newFocusIndex)
        setRedShadeIndex(newFocusIndex)
      }
      if (newFocusIndex < focusList.length && lottieRefList[newFocusIndex].current) {
        lottieRefList[newFocusIndex].current.goToAndPlay(0);
      }

    } else if (e.keyCode === 38) {//UP KEY
      let newFocusIndex = currentIndex - 1
      if (newFocusIndex >= 0 && menuExpand) {
        setFocusIndex(newFocusIndex)
        setRedShadeIndex(newFocusIndex)
      }
      if (newFocusIndex >= 0 && lottieRefList[newFocusIndex].current) {
        lottieRefList[newFocusIndex].current.goToAndPlay(0);
      }
    } else if (e.keyCode === 37) {// LEFT KEY
      setMenuExpand(true);
      setRedShadeIndex(lastFocusIndex);
    } else if (e.keyCode === 39) {// RIGHT KEY
      setFocusIndex(lastFocusIndex)
      props.toggleMenu();
      setRedShadeIndex(-1);
    } else if (e.keyCode === 13) {// ENTER KEY
      menuClick(currentIndex, focusList[currentIndex])
      props.toggleMenu()
      setRedShadeIndex(-1)
    }
  }

  return (
    <div className={styles['wrapper']} onMouseEnter={() => hoverMenu()} onMouseLeave={() => offMenu()}>
      <div className={`${menuExpand ? styles['wrapper-hover'] : ''} `} onMouseEnter={() => offMenu()}></div>
      <div className={styles['iconPosition']} onMouseEnter={() => hoverMenu()}>
        {focusList.map((data, index) => {
          return <div key={index}>
            <div className={styles['RedShadePosition']}>
              <div onClick={() => menuClick(index, data)} className={`${redShadeIndex === index ? styles['RedShade'] : ''} `} onMouseEnter={() => hoverImg(index)} onMouseLeave={() => offImg(index)}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {data.id === 'search' && <div><Lottie lottieRef={lottieRefList[index]} animationData={searchJson} autoplay={false} onDOMLoaded={() => onDOMLoaded(index)} onEnterFrame={(a) => onEnterFrame(a, index)} className={`${index === redShadeIndex || index === focusIndex || path === data.path || index === lastFocusIndex ? styles[''] : styles['menu-searchIconTranslucence']} `} /></div>}
                  {data.id === 'whatHot' && <div><Lottie lottieRef={lottieRefList[index]} animationData={homeJson} autoplay={false} onDOMLoaded={() => onDOMLoaded(index)} onEnterFrame={(a) => onEnterFrame(a, index)} className={`${index === redShadeIndex || index === focusIndex || path === data.path || index === lastFocusIndex ? styles[''] : styles['menu-homeIconTranslucence']} `} /></div>}
                  {data.id === 'liveTV' && <div><Lottie lottieRef={lottieRefList[index]} animationData={liveTVJson} autoplay={false} onDOMLoaded={() => onDOMLoaded(index)} onEnterFrame={(a) => onEnterFrame(a, index)} className={`${index === redShadeIndex || index === focusIndex || path === data.path || index === lastFocusIndex ? styles[''] : styles['menu-liveTVIconTranslucence']} `} /></div>}
                  {data.id === 'explore' && <div><Lottie lottieRef={lottieRefList[index]} animationData={exploreJson} autoplay={false} onDOMLoaded={() => onDOMLoaded(index)} onEnterFrame={(a) => onEnterFrame(a, index)} className={`${index === redShadeIndex || index === focusIndex || path === data.path || index === lastFocusIndex ? styles[''] : styles['menu-onDemandIconTranslucence']} `} /></div>}
                  {data.id === 'guide' && <div><Lottie lottieRef={lottieRefList[index]} animationData={guideJson} autoplay={false} onDOMLoaded={() => onDOMLoaded(index)} onEnterFrame={(a) => onEnterFrame(a, index)} className={`${index === redShadeIndex || index === focusIndex || path === data.path || index === lastFocusIndex ? styles[''] : styles['menu-guideIconTranslucence']} `} /></div>}
                  {data.id === 'more' && <div><Lottie lottieRef={lottieRefList[index]} animationData={moreJson} autoplay={false} onDOMLoaded={() => onDOMLoaded(index)} onEnterFrame={(a) => onEnterFrame(a, index)} className={`${index == redShadeIndex || index === focusIndex || path === data.path || index === lastFocusIndex ? styles[''] : styles['menu-moreIconTranslucence']} `} /></div>}
                </div>
                {/* <Text type="h8" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => clickMore(data)} className={`${(index === focusIndex || index === redShadeIndex) ? styles['menu-text'] : styles['menu-text-gray']} `}>{data.name}</Text> */}
              </div>
            </div>
          </div>
        })}
      </div >
    </div >
  );
}


const mapStateToProps = state => ({
  collapsed: state.menu.menuCollapsed,
  streamVod: state.app.streamVod,
  prevPath: state.app.prevPath,
  profileData: state.app.profileData,
  authenticated: state.app.authenticated,
  channelData: state.app.channelData,
  hashUserId: state.app.hashUserId,
  modal: state.app.modal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleMenu,
      setModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
